<template>
  <div class="transfer">
    <a-layout-header class="transfer-header flexR">
      <div class="userInfoView flexR">
        {{ name }} - {{ account }}
        <!-- <span v-if="name">{{ name }}</span> - <span v-if="account"> {{ account }}</span> -->
        <a-avatar>{{ name.split('')[0] }}</a-avatar>
        <div class="settImg" @click="settRef.action">
          <img src="@/assets/images/login/settingPic.png" alt="" />
        </div>
      </div>
    </a-layout-header>
    <div class="transfer-body">
      <div class="transfer-body-top">
        <a-space direction="vertical" :size="12">
          <span class="title-text">Welcome!</span>
          <!-- <span class="sub-text">Welcome Welcome Welcome Welcome Welcome</span> -->
        </a-space>
      </div>
      <div class="transfer-body-center">
        <a-space :size="40">
          <!-- <a-row :gutter="{ xs: 10, sm: 20, md: 20, lg: 20, xl: 160, xxl: 160 }"> -->
          <!-- <a-col :span="10" :offset="2"  class="flexR" style="display: flex"> -->
          <div class="transfer-body-center-item transfer-body-center-left flexT" @click="routerTo(platformNames.Amazon)">
            <img class="img" src="@/assets/images/business-img.png" />
            <a-space direction="vertical" :size="12">
              <div class="text">{{ t('setting.enter') }}</div>
              <div class="system-name">{{ t('setting.amazon') }}</div>
              <div class="text">{{ t('setting.serviceSystem1') }}</div>
            </a-space>
          </div>
          <!-- </a-col> -->
          <!-- <a-col :span="10" class="flexL" style="display: flex"> -->
          <div class="transfer-body-center-item transfer-body-center-right flexT" @click="routerTo(platformNames.Wahool)">
            <img class="img" src="@/assets/images/supplier-img.png" />
            <a-space direction="vertical" :size="12">
              <div class="text">{{ t('setting.enter') }}</div>
              <div class="system-name">{{ t('setting.wahool') }}</div>
              <div class="text">{{ t('setting.serviceSystem2') }}</div>
            </a-space>
          </div>
          <!-- </a-col> -->
          <!-- </a-row> -->
        </a-space>
      </div>
    </div>
    <div class="transfer-footer">COPYRIGHT © BEIJING SIMEJIALE TECHNOLOGY CO.LTD. ALL RIGHTS RESERVED.</div>
  </div>
  <!-- 系统设置模块 -->
  <settCpn ref="settRef"></settCpn>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import settCpn from '@/components/systemSetting/systCpn.vue';
import defaultSettings from '@/settings';
export default {
  components: {
    settCpn,
  },
  setup() {
    let { t } = useI18n();
    const store = useStore();
    const settRef = ref();
    const userInfo = computed(() => store.getters.userInfo);
    console.log('userInfo :>> ', userInfo);
    const { businessBo: { name = '' } = {}, account } = userInfo.value;
    const routerTo = async (userType) => {
      await store.dispatch('setUserType', userType);
      store.dispatch('routerRedirect');
    };
    const { platformNames } = defaultSettings;
    return {
      t,
      settRef,
      userInfo,
      name,
      account,
      routerTo,
      platformNames,
    };
  },
};
</script>

<style lang="less">
@import '/src/assets/supplier/css/custom.less';
.transfer {
  position: relative;
  .transfer-header {
    background-color: #fff;
    padding: 0 32px !important;
  }
  .userInfoView {
    // min-width: 150px;
    height: 40px;
    // color: #131011;
    font-size: 14px;
    line-height: 40px;
    .ant-avatar {
      background: #eceff1;
      width: 40px;
      height: 40px;
      margin-left: 20px;
      color: black;
      line-height: 40px;
      font-weight: bold;
      font-size: 18px;
    }
    .settImg {
      margin-left: 16px;
      cursor: pointer;
      img {
        width: 40px;
        height: 40px;
        display: flex;
      }
    }
  }
  .transfer-body {
    padding: 24px 80px 200px;
    box-sizing: border-box;

    .transfer-body-top {
      margin-bottom: 48px;

      .title-text {
        color: #131011;
        font-size: 40px;
        font-weight: 400;
        line-height: 48px;
      }

      .sub-text {
        color: #989495;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
      }
    }

    .ant-space-item {
      flex: 1;
      line-height: 1;
    }
    .transfer-body-center {
      .ant-space {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .transfer-body-center-item {
      // width: 620px;
      height: 472px;
      padding: 32px 0;
      box-sizing: border-box;
      border: 1px solid #ffc299;
      border-radius: 8px;
      transition: background-color 0.2s;
      cursor: pointer;

      &:hover {
        background-color: #fff5ec;
      }

      .img {
        width: 258px;
        height: 258px;
      }

      .text {
        color: #131011;
        font-weight: 400;
        font-size: 28px;
        text-align: center;
      }

      .system-name {
        color: @primary-color;
        font-weight: 400;
        font-size: 28px;
        text-align: center;
      }
    }
  }
  .transfer-footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    padding: 40px 0;
    color: #989495;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    background-color: #fff;
  }
}
</style>
